import { SiteActions, SiteHeirarchyState } from 'redux/types/siteActionModel';
import { LinkElement, NodeElement, SiteHeirarchyModel } from 'shared/model/SiteModel';
import {
    getChildNodes,
    getChildDevices,
    getSqftArea,
    getAvailableAndInstalledDevices,
    getSqftValueFromDevice,
} from 'shared/service/CommonService';
import { getChildId } from 'shared/service/NodeService';
import * as actionTypes from '../actionTypes';
import { Thing } from 'shared/model/DeviceModel';
import { ReceivingPollutantDataBooleanValues, SiteLocationEnum, StatusEnum } from 'shared/utils/Constants';

const initialState: SiteHeirarchyState = {
    isLoading: false,
    error: undefined,
    sites: undefined,
    isAddNodeCompleted: false,
    addingSiteerror: undefined,
    isAddingSiteLoading: false,
    currentAddingParentNode: undefined,
    selectedNode: undefined,
    initialSites: undefined,
    sitesFlow: undefined,
    selectedDevice: undefined,
    childSiteDevices: undefined,
    currentSiteDevices: undefined,
    sqftAreaByDevice: 0,
    olderSelectedNode: undefined,
    isAddSiteClicked: false,
    totalDeviceCount: 0,
    isNodeClicked: false,
    allocteClicked: false,
    allocateDevicesData: undefined,
    isAllocateDeviceDataLoading: false,
    isAllocateDeviceLoading: false,
    isUnAllocateDeviceLoading: false,
    installedClicked: false,
    installDevicesData: undefined,
    isInstallDeviceDataLoading: false,
    siteRhsData: undefined,
    siteRhsDataLoading: false,
};

export const siteHeirarchyReducer = (state = initialState, action: SiteActions): SiteHeirarchyState => {
    let selectedNode = undefined;
    let siteDetails = undefined;
    const removeSiteState = () => {
        return {
            ...state,
            isAddingSiteLoading: true,
            currentAddingNode: undefined,
            selectedNode: undefined,
        };
    };
    switch (action.type) {
        case actionTypes.FETCH_SITE_HEIRARCHY:
            selectedNode = state.selectedNode;
            return {
                ...state,
                isLoading: true,
                error: undefined,
                olderSelectedNode: selectedNode,
                currentAddingParentNode: undefined,
                selectedNode: undefined,
                sites: undefined,
                initialSites: undefined,
                isInstallDeviceDataLoading: true,
            };
        case actionTypes.FETCH_SITE_HEIRARCHY_SUCCESS:
            const links = action.payload.links.length > 1 ? action.payload.links : [];
            selectedNode = action.payload.nodes.find((node: NodeElement) => node.id === state.olderSelectedNode?.id);
            siteDetails = getSiteChildDetails(state, selectedNode);

            addDevicesDataKeys(action?.payload?.nodes);

            return {
                ...state,
                isLoading: false,
                error: undefined,
                sites: {
                    nodes: [...action.payload.nodes],
                    links: links,
                    total_device_count: action?.payload?.total_device_count,
                },
                isAddingSiteLoading: false,
                selectedNode: action.payload.nodes.find((node: NodeElement) => node.id === state.olderSelectedNode?.id),
                currentAddingParentNode: undefined,
                initialSites: {
                    nodes: [...action.payload.nodes],
                    links: links,
                    total_device_count: action?.payload?.total_device_count,
                },
                isInstallDeviceDataLoading: false,
                childSiteDevices: siteDetails.childSiteDevices,
                currentSiteDevices: siteDetails.currentSiteDevices,
                sqftAreaByDevice: siteDetails.sqftAreaOfDevices,
                totalDeviceCount: action?.payload?.total_device_count,
            };
        case actionTypes.FETCH_SITE_HEIRARCHY_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload.error,
                sites: undefined,
                isAddingSiteLoading: false,
            };

        case actionTypes.ADD_NEW_NODE:
            if (state.currentAddingParentNode) {
                return { ...state };
            }
            const currentNode = state.sites?.nodes.find((node: NodeElement) => node.id === action.payload.id);
            return {
                ...state,
                isAddNodeCompleted: true,
                error: null,
                currentAddingParentNode: currentNode,
                selectedNode: undefined,
            };
        case actionTypes.SET_SITE_FLOW:
            return {
                ...state,
                isAddNodeCompleted: false,
                isAddingSiteLoading: false,
                sitesFlow: action.payload,
            };
        case actionTypes.REMOVE_NEW_NODE:
            return {
                ...state,
                isAddingSiteLoading: true,
                currentAddingParentNode: undefined,
                selectedNode: undefined,
                sites: {
                    nodes: state.sites?.nodes ? [...state.sites.nodes] : [],
                    links: state.sites?.links ? [...state.sites.links] : [],
                    total_device_count: state?.sites?.total_device_count ? state.sites.total_device_count : 0,
                },
                isAddNodeCompleted: true,
            };
        case actionTypes.SELECT_NODE:
            selectedNode = state.sites?.nodes.find((node: NodeElement) => node.id === action.payload.id);
            siteDetails = getSiteChildDetails(state, selectedNode);
            return {
                ...state,
                selectedNode: selectedNode,
                currentAddingParentNode: undefined,
                childSiteDevices: siteDetails.childSiteDevices,
                currentSiteDevices: siteDetails.currentSiteDevices,
                sqftAreaByDevice: siteDetails.sqftAreaOfDevices,
            };
        case actionTypes.REMOVE_SITE:
            return removeSiteState();
        case actionTypes.HIDE_SITE:
            return showHideNodes(state, action.payload.id, true);
        case actionTypes.SHOW_SITE:
            return showHideNodes(state, action.payload.id, undefined);
        case actionTypes.REMOVE_SELECTED_NODE:
            return {
                ...state,
                selectedNode: undefined,
                childSiteDevices: undefined,
                currentSiteDevices: undefined,
                sqftAreaByDevice: 0,
            };
        case actionTypes.SET_SITE_SELECTED_DEVICE:
            return {
                ...state,
                selectedDevice: action.payload.thing,
            };
        case actionTypes.REMOVE_SITE_SELECTED_DEVICE:
            return {
                ...state,
                selectedDevice: undefined,
            };
        case actionTypes.ADD_SITE_CLICKED:
            return {
                ...state,
                isAddSiteClicked: action?.payload,
            };
        case actionTypes.NODE_CLICKED:
            return {
                ...state,
                isNodeClicked: action?.payload,
            };
        case actionTypes.SET_ALLOCATE_CLICKED:
            return {
                ...state,
                allocteClicked: action?.payload,
            };
        case actionTypes.FETCH_ALLOCATE_DEVICES:
        case actionTypes.ALLOCATE_DEVICE:
        case actionTypes.UN_ALLOCATE_DEVICE:
            return {
                ...state,
                isAllocateDeviceDataLoading: true,
            };
        case actionTypes.FETCH_ALLOCATE_DEVICES_SUCCESS:
        case actionTypes.ALLOCATE_DEVICE_SUCCESS:
        case actionTypes.UN_ALLOCATE_DEVICE_SUCCESS:
            return {
                ...state,
                allocateDevicesData: action?.payload,
                isAllocateDeviceDataLoading: false,
            };
        case actionTypes.FETCH_ALLOCATE_DEVICES_FAILURE:
        case actionTypes.ALLOCATE_DEVICE_FAILURE:
        case actionTypes.UN_ALLOCATE_DEVICE_FAILURE:
            return {
                ...state,
                isAllocateDeviceDataLoading: false,
            };
        case actionTypes.SET_INSTALLED_CLICKED:
            return {
                ...state,
                installedClicked: action?.payload,
            };
        case actionTypes.FETCH_INSTALL_DEVICES:
        case actionTypes.INSTALL_DEVICE:
        case actionTypes.UNINSTALL_DEVICE:
            return {
                ...state,
                isInstallDeviceDataLoading: true,
            };
        case actionTypes.FETCH_INSTALL_DEVICES_SUCCESS:
        case actionTypes.INSTALL_DEVICE_SUCCESS:
        case actionTypes.UNINSTALL_DEVICE_SUCCESS:
            return {
                ...state,
                installDevicesData: action?.payload,
                isInstallDeviceDataLoading: false,
            };
        case actionTypes.FETCH_INSTALL_DEVICES_FAILURE:
        case actionTypes.INSTALL_DEVICE_FAILURE:
        case actionTypes.UNINSTALL_DEVICE_FAILURE:
            return {
                ...state,
                isInstallDeviceDataLoading: false,
            };

        case actionTypes.FETCH_SITE_RHS:
            return {
                ...state,
                siteRhsDataLoading: true,
            };
        case actionTypes.FETCH_SITE_RHS_SUCCESS:
            return {
                ...state,
                siteRhsData: action?.payload,
                siteRhsDataLoading: false,
            };
        case actionTypes.FETCH_SITE_RHS_FAILURE:
            return {
                ...state,
                siteRhsDataLoading: false,
            };
        default:
            return {
                ...state,
            };
    }
};

const showHideNodes = (state: SiteHeirarchyState, id: any, showOrHide: boolean | undefined): SiteHeirarchyState => {
    if (state.sites) {
        let hidingDevicesid: any = getChildId(state.sites?.nodes, id, []);
        if (hidingDevicesid.length > 0) {
            hidingDevicesid = hidingDevicesid.filter(function (x: any, i: any, a: any) {
                return a.indexOf(x) == i;
            });
        }
        state.sites?.nodes.map((node: NodeElement) => {
            if (node.id === id) {
                node.isChildHidden = showOrHide;
            }
        });
        const updatedHideSites: SiteHeirarchyModel = { ...state.sites };
        if (hidingDevicesid.length > 0) {
            updatedHideSites.nodes.forEach((node: NodeElement) => {
                if (hidingDevicesid.includes(node.id)) {
                    node.hidden = showOrHide;
                }
            });
            updatedHideSites.links.forEach((link: LinkElement) => {
                if (hidingDevicesid.includes(link.source_id) || hidingDevicesid.includes(link.target_id)) {
                    link.hidden = showOrHide;
                }
            });
        }
        return {
            ...state,
            sites: {
                nodes: [...updatedHideSites.nodes],
                links: [...updatedHideSites.links],
                total_device_count: updatedHideSites.total_device_count,
            },
            isAddNodeCompleted: true,
        };
    } else {
        return { ...state };
    }
};

const getSiteChildDetails = (state: SiteHeirarchyState, selectedNode?: NodeElement) => {
    let sqftAreaOfDevices = 0;
    let childSiteDevices = undefined;
    let currentSiteDevices = undefined;

    if (state.sites?.nodes && selectedNode && state.sites?.nodes?.length > 0) {
        const arryChilds = getChildId(state.sites?.nodes, selectedNode.id, []);
        const childNodes = getChildNodes(state.sites?.nodes, arryChilds);
        const childDevices = getChildDevices(childNodes);
        sqftAreaOfDevices = getSqftArea(childNodes);
        childSiteDevices = childDevices && getAvailableAndInstalledDevices(childDevices);
    }
    if (selectedNode?.data?.thing_list) {
        currentSiteDevices = getAvailableAndInstalledDevices(selectedNode.data.thing_list);
        sqftAreaOfDevices += Math.round(getSqftValueFromDevice(currentSiteDevices.installed)) ?? 0;
    }
    return {
        sqftAreaOfDevices,
        currentSiteDevices,
        childSiteDevices,
    };
};

/*This function will call the other functions to calculate the child sites of site and add keys to the existing data  */
const addDevicesDataKeys = (site: NodeElement[]) => {
    site?.map((item: NodeElement) => {
        const arryChilds = getChildId(site, item?.id, []);
        const childNodes = getChildNodes(site, arryChilds);
        const childDevices = getChildDevices(childNodes);
        const childDevice = childDevices && getAvailableAndInstalledDevices(childDevices);

        // check if any child is online then add isAnyChildOnline key and set to true
        const isAnyChildOnline = isAnyDeviceOnline(childDevice?.installed);
        item.isAnyChildOnline = isAnyChildOnline;

        const devicesThingList = item?.data?.thing_list;
        const filteredDevices = filteredDevicesList(devicesThingList) ?? [];
        const filteredChildDevices = filteredDevicesList(childDevice?.installed);
        const totalDevice = [...filteredDevices, ...(filteredChildDevices ?? [])];
        const totalAQI = totalDevice?.reduce((sum, device) => sum + Number(device.attributes.input_aqi), 0);
        const count = totalDevice?.length;
        const averageAQI = count && count > 0 ? totalAQI && totalAQI / count : 0;
        // Check if any one device is online or not if yes then add new key and set to true
        const isAnyOnline = isAnyDeviceOnline(devicesThingList);
        item.isAnyOnline = isAnyOnline;
        // Check if site is Root node and if yes then set isRoot to true
        item.isRoot = item?.id === item?.parent_id && isAnyOnline ? true : false;
        // add site_aqi key if devices data is present
        if (item?.data?.thing_list || childDevices?.length) {
            item.site_aqi = averageAQI;
        }
    });
};

const filteredDevicesList = (devices: Thing[] | undefined) => {
    return devices?.filter(
        (device) =>
            device.attributes.receiving_pollutant_data === ReceivingPollutantDataBooleanValues.TRUE &&
            device.attributes.input_aqi_category != StatusEnum.DATAERROR &&
            device.attributes.location === SiteLocationEnum.AtSiteInstalled,
    );
};

/*This function accepts site data and check if device is online and atsite installed and device is not of DataError category if yes then retun true else false */
const isAnyDeviceOnline = (devices: Thing[] | undefined): boolean => {
    return (
        devices?.some((device) => {
            return (
                device?.attributes?.receiving_pollutant_data === ReceivingPollutantDataBooleanValues.TRUE &&
                device?.attributes?.location === SiteLocationEnum.AtSiteInstalled &&
                device?.attributes?.input_aqi_category != StatusEnum.DATAERROR
            );
        }) ?? false
    );
};
