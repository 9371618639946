import { CProgressBar, CToast, CToastBody, CToaster, CToastHeader } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducers';
import { toasterColorMap, ToastVariant } from 'shared/utils/Constants';

interface ToasterI {
    autohide: boolean | number;
    closeButton: boolean;
    fade: boolean;
    variant: ToastVariant;
    message: string;
}

const autohide = true;
const autohideValue = 5000;
const closeButton = true;
const fade = true;

const Toaster = () => {
    const toastState = useSelector((state: AppState) => state.toastStateReducer);
    const [toasts, setToasts] = useState<ToasterI[]>([]);

    const addToast = (variant: ToastVariant, message: string) => {
        setToasts([...toasts, { autohide: autohide && autohideValue, closeButton, fade, variant, message }]);
    };

    useEffect(() => {
        if (toastState && toastState.variant) {
            addToast(toastState.variant, toastState.message);
        }
    }, [toastState]);

    return (
        <CToaster position="top-right" style={{ zIndex: 9999999 }}>
            {toasts.map((toast: ToasterI) => (
                <ToastWithProgress
                    key={`toast${toast.message}${toast.variant}`}
                    autohideValue={typeof toast.autohide === 'number' ? toast.autohide : autohideValue}
                    toast={toast}
                />
            ))}
        </CToaster>
    );
};

interface ToastWithProgressProps {
    autohideValue: number;
    toast: ToasterI;
}

const ToastWithProgress: React.FC<ToastWithProgressProps> = ({ autohideValue, toast }) => {
    const [progress, setProgress] = useState(100);

    useEffect(() => {
        const startTime = Date.now();
        const interval = setInterval(() => {
            const elapsedTime = Date.now() - startTime;
            const percentageRemaining = Math.max(0, 100 - (elapsedTime / autohideValue) * 100);
            setProgress(percentageRemaining);
            if (percentageRemaining <= 0) {
                clearInterval(interval);
            }
        }, 50);

        return () => clearInterval(interval);
    }, [autohideValue]);

    return (
        <CToast color={toast.variant} show={true} autohide={toast.autohide} fade={toast.fade}>
            <CToastHeader className={`${toast.variant}  `} closeButton={toast.closeButton}>
                <div className="d-flex align-items-center pt-2">
                    <em
                        className="ri-information-fill w-10 h2 mr-3"
                        style={{ color: toasterColorMap[toast.variant] }}
                    />
                    <div className="font-body toaster-body-font ">{toast.message}</div>
                </div>
            </CToastHeader>

            <CProgressBar
                className="mt-2 toaster-progress-bar "
                style={{
                    width: `${progress}%`,
                    backgroundColor: toasterColorMap[toast.variant],
                }}
            />
        </CToast>
    );
};

export default Toaster;
